@import "minima";

body {
  color: black;
  background-color: #fff;
}

footer {
  color: black;
  background-color: #555;
}
.footer-description {
  color: orange;
  background-color: #555;
}

header {
  color: black;
  background-color: #cc8;
}

.post-header {
  color: black;
  background-color: #fff;
}
